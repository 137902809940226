import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Button, Col, Row } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import { navigate } from "gatsby-link"
import React from "react"

import styles from "./style.module.less"

const ExpiredBill = (props) => {
  const { day } = props

  return (
    <Row
      justify="center"
      className={cx("expiredBillContext mt72", styles.expiredBillContext)}
    >
      <Col
        span={24}
        md={12}
        lg={16}
        xl={12}
        className="flex-column justify-center items-center"
      >
        <Icon name="Bill" />
        <span className="fw5 f20 mine-shaft mt32">
          {translate("expiredBill")}
        </span>
        <span className="f14 scorpion-main mt8 tc">
          {translate("expiredBill.description", {
            dayText: (
              <span className="fw6">{translate("dayN", { day: day })}</span>
            ),
          })}
        </span>
        <Link className="mv24">
          <span className="kimberly underline fw6 mv24">
            {translate("editPayment")}
          </span>
        </Link>
        <Button
          type="primary"
          block
          className={styles.button}
          onClick={() => navigate("/boleto/novo-boleto")}
        >
          {translate("generateBill")}
        </Button>
      </Col>
    </Row>
  )
}

export default ExpiredBill
