import TranslateWrapper from "@components/TranslateWrapper"
import ExpiredBill from "@modules/Payment/ExpiredBill"
import React, { useEffect, useState } from "react"

const ExpiredBillPage = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  const day = "21/10"

  return (
    <TranslateWrapper>
      <div className="mc center">
        <ExpiredBill day={day} />
      </div>
    </TranslateWrapper>
  )
}

export default ExpiredBillPage
